import React,{Component, Fragment} from 'react'
import { connect } from 'react-redux'
import {Link } from 'react-router-dom'
import Button from 'antd/lib/button'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Input from 'antd/lib/input'
import {rootReducer} from './../../Store/AppStore'
import {DashboardFilled, ReconciliationFilled, UserOutlined, TeamOutlined, NotificationFilled, EnvironmentFilled, FileSearchOutlined, PushpinFilled, ToolOutlined, LogoutOutlined, MenuOutlined} from '@ant-design/icons'


class Sidebar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            opened: false,
            sidebarOpen: true,
            sidebarTrigger: '',
            title: this.props.title || null,
            user: this.props.user || null,
            selected_nav: this.props.selected,
        }
        this.toggleSidebar = this.toggleSidebar.bind(this)
        this.setTrigger = this.setTrigger.bind(this)
        this.logout = this.logout.bind(this)
      
        if(localStorage.getItem('sidebar') === 'sidebar-closed'){
            document.body.classList.add('sidebar-closed');
        }
    }

    static getDerivedStateFromProps(props, state) {
        let update = {};
        if (props.selected !== state.selected_nav) {
            update.selected_nav = props.selected
        }
        return Object.keys(update).length ? update : null;
    }


    setTrigger(e, val){    
        if(document.body.classList.contains('sidebar-closed'))
        {
            this.setState({sidebarTrigger: 'visible'})
        }
        else{
            if(e.target.classList.contains('global-sidebar') || e.target.classList.contains('toggle-sidebar')){
                this.setState({sidebarTrigger: val})
            }
            else
            {
                this.setState({sidebarTrigger: val})
            }
        }
    }

    toggleSidebar(){
        let {sidebarOpen} = this.state;
        if(document.body.classList.contains('sidebar-closed'))
        {
            document.body.classList.remove('sidebar-closed');
            localStorage.removeItem('sidebar');
        }
        else
        {
            document.body.classList.add('sidebar-closed');
            localStorage.setItem('sidebar', 'sidebar-closed');
        }
        this.setState({sidebarOpen: !sidebarOpen})          
    }

    logout(){
        // localStorage.removeItem('user');
        // window.location = '/login';
        
            const {dispatch} = this.props
            dispatch(() => rootReducer(
                    {}, 
                    {
                        type: "USER_LOGOUT"
                    })
                )    
    }

    render(){
        let {sidebarOpen, sidebarTrigger, selected_nav, user} = this.state
        
        return(
            <Fragment>
                <div className="global-header">                    
                    <div className="logo">
                        <img src="assets/images/deliveryapp-logo.png" alt="Delivery App"/>
                    </div>
                    <div className="header-inner">
                        <Row gutter={32} align={'middle'}>
                            <Col span={6}>
                                {this.props.title}
                            </Col>
                            <Col span={12}>
                                <Input.Search  placeholder="Search e.g. Driver Name, Job Title, Job Number...." onSearch={value => console.log(value)} style={{ width: '100%' }} />
                            </Col>
                            <Col span={6} className="text-right">                                
                                <div className="meta-nav">
                                    Welcome  <em>{ user.first_name + ' '+ user.last_name }</em>
                                    <Link to="#"><NotificationFilled /></Link>
                                    <Link to="#"><UserOutlined /></Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
                <div className={"global-sidebar " + sidebarOpen} onMouseOver={(e) => this.setTrigger(e, 'visible')}  onMouseOut={(e) => this.setTrigger(e, '')}>
                    <div className="sidebar-contents">
                        <ul>
                            <li className={selected_nav === "dashboard" ? 'selected' : ''}><Link to="/"><DashboardFilled /> <span>Dashboard</span></Link></li>
                            <li className={selected_nav === "jobs" ? 'selected' : ''}><Link to="/jobs"><ReconciliationFilled /> <span>Jobs</span></Link></li>
                            <li className={selected_nav === "drivers" ? 'selected' : ''}><Link to="/drivers"><TeamOutlined /> <span>Drivers</span></Link></li>


                            
                            {/* <li className={selected_nav === "jobs" ? 'selected' : ''}><Link to="/candidates"><DeploymentUnitOutlined /> <span>Candidates</span></Link></li> */}
                            <li className={selected_nav === "fleet" ? 'selected' : ''}><Link to="/fleet"><FileSearchOutlined /> <span>Fleet Details</span></Link></li>
                            <li className={selected_nav === "messages" ? 'selected' : ''}><Link to="/messages"><NotificationFilled /> <span>Messages</span></Link></li>
                            <li className={selected_nav === "map-logs" ? 'selected' : ''}><Link to="/map-logs"><EnvironmentFilled /> <span>Map Logs</span></Link></li>
                            <li className={selected_nav === "push-logs" ? 'selected' : ''}><Link to="/push-logs"><PushpinFilled /> <span>Push Logs</span></Link></li>

                            
                            
                        </ul>
                        <hr className="nav-seperator"/>
                        <ul>
                            {
                                this.state.role !== 'RECRUITER' &&
                                <Fragment>
                                    {/* <li className={selected_nav === "administration" ? 'selected' : ''}><Link to="/"><ToolOutlined /> <span>Administration</span></Link></li>
                                    <li className={selected_nav === "settings" ? 'selected' : ''}><Link to="/"><SettingOutlined /> <span>Settings</span></Link></li> */}
                                    <li className={selected_nav === "settings" ? 'selected' : ''}><Link to="/settings"><ToolOutlined /> <span>Settings</span></Link></li>
                                    {/* <li className={selected_nav === "users" ? 'selected' : ''}><Link to="/administration"><TeamOutlined /> <span>Users</span></Link></li> */}
                                </Fragment>
                            }
                            <li><Link to="#" onClick={this.logout}><LogoutOutlined /> <span>Logout</span></Link></li>
                        </ul>

                    </div>
                </div>
                <div className="global-content">
                    <Button className={"toggle-sidebar "+sidebarTrigger} onClick={this.toggleSidebar} onMouseOver={(e)=>this.setTrigger(e, 'visible')} onMouseOut={(e) => this.setTrigger(e, '')}  icon={<MenuOutlined />}/>                    
                    {this.props.children}                    
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {        
    // let {user} = state.auth
    return { 
        state
        };
}
export default connect(mapStateToProps)(Sidebar)