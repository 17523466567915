import {FirebaseConstants} from './../Actions/Types'
const initialState = {
    drivers: null,
    jobs: null
};

export function FirebaseReducer(state = initialState, action) {
    switch (action.type) {
        
        case FirebaseConstants.DRIVERS:
            return {
                ...state,
                drivers: action.payload,
            }  
            
        case FirebaseConstants.JOBS:
            return {
                ...state,
                jobs: action.payload,
            }  

        case FirebaseConstants.SETJOB:
            return {
                ...state,
                jobs: state.jobs ? [...state.jobs, action.payload] : action.payload
            }

        default:
            return {
                ...state
            }
            

    }
}