import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import {isEmpty} from './../Helpers'
import {rootReducer} from './../Store/AppStore'
import Sidebar from './Sidebar'

export default function (ComposedComponent) {

    class DashboardLayout extends Component {
        constructor(props)
        {
            super(props)
            this.state = {
                // user: parseJwt(localStorage.getItem('adal.idtoken')),
                user: {},
                loggedIn: true,
                waiting: true,
                selected_nav: 'dashboard',
                meta_visible: false,
                current_title: null
            }
            this.onChangeNav = this.onChangeNav.bind(this)
            this.setTitle = this.setTitle.bind(this)
            this.logout = this.logout.bind(this)

            
        }
    
        onChangeNav(selected_nav)
        {            
            this.setState({selected_nav})
        }

        setTitle(current_title)
        {        
            this.setState({current_title})
        }

        logout(e)
        {
            e.preventDefault();
            const {dispatch} = this.props
            dispatch(() => rootReducer(
                    {}, 
                    {
                        type: "USER_LOGOUT"
                    })
                )            
        }

        componentDidMount(){
            this.mounted = true
            this.setState({waiting: true})           
        }
        
        static getDerivedStateFromProps(props, state) {
            let update = {};
            if (props.user !== state.user) {
                update.user = props.user
            }
            update.waiting = false
            return Object.keys(update).length ? update : null;
        }

        componentDidUpdate(prevProps, prevState) {
            if(isEmpty(this.props.user) && !this.state.waiting){
                console.log('Logging Out');
                window.location= '/login'
            }

        }

   


        render() {
            const {user, waiting, selected_nav} = this.state
            if(waiting)
            {
                return (<div>Waiting...</div>)
            }
            
            if(!waiting && !isEmpty(user))
            {
                return(
                    <Fragment>
                        <Sidebar user={user} title={this.state.current_title} selected={selected_nav}>
                            <ComposedComponent  activeNav={this.onChangeNav} setTitle={this.setTitle} loggedUser={user} {...this.props} />                    
                        </Sidebar>
                    </Fragment>
                )
            }
            return (
                <Fragment>

                </Fragment>
            )
        }
    }

    function mapStateToProps(state) {        
        let {user} = state.auth
        return { 
            user
            };
    }
    return connect(mapStateToProps)(DashboardLayout)
}