import {JobConstants} from './../Actions/Types'
const initialState = {
    jobs: [],
};

export function JobReducer(state = initialState, action) {
    switch (action.type) {
        
        case JobConstants.GET:
            return {
                ...state,
                jobs: action.payload.jobs,
            }    
            
        case JobConstants.ADD:
            return {
                ...state,
                jobs: [...state.jobs, action.payload.job],
            }

        case JobConstants.UPDATE:
            let jobs_update = [...state.jobs]
            let dindex = jobs_update.findIndex((job) => {return job.id === action.payload.job.id});
            if(dindex !== -1){
                jobs_update[dindex] = action.payload.job
            }
            return {
                ...state,
                jobs: jobs_update
            }

        default:
            return {
                ...state
            }
            

    }
}