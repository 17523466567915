import {AuthConstants} from './../Actions/Types'
import { TOKEN_NAME } from './../../Config'
let token = localStorage.getItem(TOKEN_NAME);
let user = localStorage.getItem('user');

const initialState = {
    user: JSON.parse(user) || {},
    token: token,
};

// const initialState = token ? { loggedIn: true, token: token  } : { loggingIn: false };

export function AuthReducer(state = initialState, action) {
    switch (action.type) {
        
        case AuthConstants.LOGIN:
            return {
                ...state,
                user: action.payload.user,
                token: action.payload.token,
            }            
        default:
            return {
                ...state
            }
            

    }
}