import {DriverConstants} from './../Actions/Types'
const initialState = {
    drivers: [],
};

// const initialState = token ? { loggedIn: true, token: token  } : { loggingIn: false };

export function DriverReducer(state = initialState, action) {
    switch (action.type) {
        
        case DriverConstants.GET:
            return {
                ...state,
                drivers: action.payload.drivers,
            }    
            
        case DriverConstants.ADD:
            return {
                ...state,
                drivers: [...state.drivers, action.payload.driver],
            }

        case DriverConstants.UPDATE:
            let drivers_update = [...state.drivers]
            let dindex = drivers_update.findIndex((driver) => {return driver.id === action.payload.driver.id});
            if(dindex !== -1){
                drivers_update[dindex] = action.payload.driver
            }
            return {
                ...state,
                drivers: drivers_update
            }

        default:
            return {
                ...state
            }
            

    }
}