export const USER_LOGOUT = 'USER_LOGOUT'

export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    FAILED: 'AUTH_FAILED'
}

export const DriverConstants = {
    GET: 'ALL_DRIVERS',
    ADD: 'ADD_DRIVER',
    UPDATE: 'UPDATE_DRIVERS',
    DELETE: 'DELETE_DRIVERS',
    FAILED: 'DRIVERS_FAILED'
}

export const JobConstants = {
    GET: 'ALL_JOBS',
    ADD: 'ADD_JOB',
    UPDATE: 'UPDATE_JOB',
    DELETE: 'DELETE_JOB',
    FAILED: 'JOBS_FAILED'
}

export const FirebaseConstants = {
    DRIVERS: 'LIST_DRIVERS_FB',
    JOBS: 'LIST_JOBS_FB',
    SET_JOB: 'SET_JOB_FB',
}