import { combineReducers } from 'redux';
import { AuthReducer } from './AuthReducer';
import { DriverReducer } from './DriverReducer';
import { JobReducer } from './JobReducer';
import { FirebaseReducer } from './FirebaseReducer'

export default combineReducers({
    auth: AuthReducer,
    drivers: DriverReducer,
    jobs: JobReducer,
    firebase: FirebaseReducer
});