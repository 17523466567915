import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

export default function (ComposedComponent) {

    class AuthLayout extends Component {
        constructor(props)
        {
            super(props)
            this.state = {
            }
        }
    
   

        componentDidMount(){
            this.mounted = true
        }
        
       render() {
            return(
                <Fragment>
                    <div className="auth-wrapper">
                        <div className="auth-table">
                            <div className="auth-image">
                                
                            </div>
                            <div className="auth-contents">
                                <ComposedComponent {...this.props} />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )
        }
    }

    function mapStateToProps(state) {                
        return {
            state
        };
    }
    return connect(mapStateToProps)(AuthLayout)
}