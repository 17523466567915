import React from 'react'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Result from 'antd/lib/result'
import Button from 'antd/lib/button'

const loaderIcon = <LoadingOutlined style={{ fontSize: 44, color: '#2E90BC' }} spin />;

export const Spinner = () => {
    return (
        <div className="full-loader">
            <Spin indicator={loaderIcon} />
        </div>
        );
}

export const Page404 = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={<Button href="/" type="primary">Home</Button>}
        />
    );
  };