import React, { Component, lazy, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import {Spinner, Page404} from './../Components'
import AuthLayout from './../Layouts/AuthLayout';
import DashboardLayout from './../Layouts/DashboardLayout';
const Login = lazy(() => import(/*webpackChunkName: "auth-login" */ './../Views/Auth/Login'))
const Dashboard = lazy(() => import(/*webpackChunkName: "dashboard" */ './../Views/Dashboard'))
const Drivers = lazy(() => import(/*webpackChunkName: "drivers" */ './../Views/Drivers'))
const Jobs = lazy(() => import(/*webpackChunkName: "jobs" */ './../Views/Jobs'))
const Messages = lazy(() => import(/*webpackChunkName: "messages" */ './../Views/Messages'))
export default class Routes extends Component {
    
    render() {
        return (
            <Suspense fallback={<Spinner />}>
                    <Router>                                        
                        <Switch>
                            <Route exact path="/" component={DashboardLayout(Dashboard)} />
                            <Route exact path="/login" component={AuthLayout(Login)} />
                            <Route exact path="/drivers" component={DashboardLayout(Drivers)} />
                            <Route exact path="/jobs/:number?" component={DashboardLayout(Jobs)} />
                            <Route exact path="/messages" component={DashboardLayout(Messages)} />
                            {/* 
                            <Route exact path="/clients" component={DashboardLayout(Clients)} />
                            <Route exact path="/contacts" component={DashboardLayout(Contacts)} />
                            <Route exact path="/candidates" component={DashboardLayout(Candidates)} />
                            <Route exact path="/jobs" component={DashboardLayout(Jobs)} />
                            <Route exact path="/administration" component={DashboardLayout(Users)} />
                            <Route exact path="/reports" component={DashboardLayout(Reports)} />
                            <Route exact path="/r2r/:id" component={R2R} /> */}
                            <Route component={Page404} />
                        </Switch>
                                        
                    </Router>
                </Suspense>
            
        )
    }
}