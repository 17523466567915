export const TOKEN_NAME = 'deliveryapp-auth-token'
export const BASE_PATH = ''

// export const API_URL = 'http://192.168.18.247/deliveryapp/public/api/'
// export const ASSETS_PATH = 'http://192.168.18.247/deliveryapp/public/assets/'
// export const API_URL = 'http://192.168.18.247/deliveryapp/public/api/'
// export const ASSETS_PATH = 'http://192.168.18.247/deliveryapp/public/assets/'
export const DEFAULT_AVATAR = 'assets/images/default_avatar.png'

// export const API_URL = 'http://localhost/deliveryapp/public/api/'
// export const ASSETS_PATH = 'http://localhost/deliveryapp/public/assets/'

export const API_URL = 'http://dms.web-tronix.com/dms-api/public/api/'
export const ASSETS_PATH = 'http://dms.web-tronix.com/dms-api/public/assets/'
// export const DEFAULT_AVATAR = 'assets/images/default_avatar.png'

export const firebaseConfig = {
    apiKey: "AIzaSyB2W2QRAXHbLcate9NGsxaUddISC3oo7Rc",
    authDomain: "deliveryapp-942c2.firebaseapp.com",
    databaseURL: "https://deliveryapp-942c2.firebaseio.com",
    projectId: "deliveryapp-942c2",
    storageBucket: "deliveryapp-942c2.appspot.com",
    messagingSenderId: "445839520152",
    appId: "1:445839520152:web:e43980390aea15787bbbed"
};