import React from 'react';

export const  parseJwt =  (token) => {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export const titleCase = (str) =>{
    var sentence = str.toLowerCase().split(" ");
    for(var i = 0; i< sentence.length; i++){
        sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ");

    // return sentence;
}

export const  mergeObjects = (a1, a2) => {
    // return  a1.map(itm => ({
    //             let f = a2.find((item) => (item.id === itm.id) && item),
    //             ...itm
    //         }));

    let new_array = [];
    a1.map((itema) => {
                    let f = a2.find((itemb) => (itemb.id === itema.id) && itemb)
                    if(f)
                    {
                        new_array.push({...itema, ...f})
                    }
                    else{
                        new_array.push(itema)
                    }
                })
    return new_array;
}


export const jobsByStatus = (data, status) => {
    return  data.filter(d => d.status === status)
    // fb_jobs.filter(j => j.user_id === driver.id)
}

export const driverByStatus = (data, status) => {
    return  data.filter(d => d.online === status)
    // fb_jobs.filter(j => j.user_id === driver.id)
}

export const getStatusLabel = ( name = "CONTACTED") =>{
    switch(name)
    {
        case "CONTACTED":
        default:
            return "#828E9C";
        
        case "INTERNAL SUBMISSION":
            return "#2EBAFA";

        case "CLIENT SUBMISSION":
            return "#3C3ACE";
        
        case "TELEPHONIC INTERVIEW":
            return "#3C3ACE";

        case "IN-PERSON INTERVIEW":
            return "#1C1A80";

        case "OFFER":
            return "#FCAA3A";

        case 'PLACEMENT':
            return "#23AC4C"
        
        case 'DNC':
            return "#E33E29"
    }
}

export const getJobStatusLabel = ( name = "OPEN") =>{
    switch(name)
    {
        case "OPEN":
            return "#25AA4D";
        
        case "CLOSED":
            return "#E83D27";

        case "ONHOLD":
            return "#FCAA3A";
        
        default:
            return "#485460";
    }
}


export const SmartLoader = () => (
    <svg width="2em" height="0.7em" fill="currentColor" viewBox="0 0 120 30">
        <circle cx="15" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="60" cy="15" r="9" fillOpacity="0.3">
            <animate attributeName="r" from="9" to="9"
                    begin="0s" dur="0.8s"
                    values="9;15;9" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="0.5" to="0.5"
                    begin="0s" dur="0.8s"
                    values=".5;1;.5" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
        <circle cx="105" cy="15" r="15">
            <animate attributeName="r" from="15" to="15"
                    begin="0s" dur="0.8s"
                    values="15;9;15" calcMode="linear"
                    repeatCount="indefinite" />
            <animate attributeName="fillOpacity" from="1" to="1"
                    begin="0s" dur="0.8s"
                    values="1;.5;1" calcMode="linear"
                    repeatCount="indefinite" />
        </circle>
    </svg>
)

export const handleResponse = (response) => {
    return response.text()
        .then((my_data) => {
            if(response.status === 404)
            {
                return Promise.reject('Error 404: Requested uri does not exist.')
            }

            if(response.status === 401)
            {
                localStorage.removeItem('user');
                window.location = '/login';
                // return Promise.reject('Error 404: Requested uri does not exist.')
                return null;
            }

            if(response.status === 400)
            {
                const data = my_data && JSON.parse(my_data);
                return Promise.reject(data)
            }
            const data = my_data && JSON.parse(my_data);
            if (!response.ok) {
                // throw Error(response.statusText);
                const error = (data && data.error) || response.statusText;
                return Promise.reject(error);
            }

            return data
        })
        .catch(error => {
            return Promise.reject(error);
        });
}